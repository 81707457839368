export const screen = {
  INITIAL_SCREEN: "INITIAL_SCREEN",
  STAMP_DUTY_SCREEN: "STAMP_DUTY_SCREEN",
  AGENCY_FEES_SCREEN: "AGENCY_FEES_SCREEN",
  CONVEYANCING_SCREEN: "CONVEYANCING_SCREEN",
  VALUATION_SURVEY_SCREEN: "VALUATION_SURVEY_SCREEN",
  OTHER_COSTS_SCREEN: "OTHER_COSTS_SCREEN",
  CHOOSE_FINANCE_SCREEN: "CHOOSE_FINANCE_SCREEN",
  RESULT_SCREEN: "RESULT_SCREEN",
};

export const calculatorTypes = {
  BUY: "BUY",
  SELL: "SELL",
  BUY_SELL: "BUY_SELL",
  NONE: "NONE",
};

export const valuationTypeValues = { NONE: 0, SMALL: 0, MID: 540, FULL: 700 };

export const buyScreens = [
  screen.STAMP_DUTY_SCREEN,
  screen.CONVEYANCING_SCREEN,
  screen.VALUATION_SURVEY_SCREEN,
  screen.OTHER_COSTS_SCREEN,
  screen.RESULT_SCREEN,
];

export const sellScreens = [
  screen.AGENCY_FEES_SCREEN,
  screen.CONVEYANCING_SCREEN,
  screen.OTHER_COSTS_SCREEN,
  screen.RESULT_SCREEN,
];

export const buySellScreens = [
  screen.STAMP_DUTY_SCREEN,
  screen.AGENCY_FEES_SCREEN,
  screen.CONVEYANCING_SCREEN,
  screen.VALUATION_SURVEY_SCREEN,
  screen.OTHER_COSTS_SCREEN,
  screen.RESULT_SCREEN,
];
