import { createSlice } from "@reduxjs/toolkit";
import {
  calculatorTypes,
  screen,
  valuationTypeValues,
} from "../../components/Calculators/MovingCostCalculator/constants";

const initialState = {
  screenIndex: -1,
  calculatorScreens: [],
  calculatorType: calculatorTypes.NONE,
  currentScreen: screen.INITIAL_SCREEN,
  buyPrice: 0,
  buyConveyancingCosts: 600,
  sellPrice: 0,
  sellConveyancingCosts: 600,
  agencyFee: 0,
  isBuyToLet: false,
  stampDutyCosts: 0,
  otherLegalCosts: 350,
  valuationTypeValue: valuationTypeValues.NONE,
  removalStorageCosts: 0,
  otherMovingCosts: 200,
};

export const movingCostCalculatorSlice = createSlice({
  name: "movingCostCalculator",
  initialState,
  reducers: {
    incrementScreenIndex: (state) => {
      state.screenIndex += 1;
    },
    decrementScreenIndex: (state) => {
      state.screenIndex -= 1;
      if (state.screenIndex < 0) state.screenIndex = -1;
    },
    resetScreenIndex: (state) => {
      state.screenIndex = 0;
    },
    setCalculatorScreens: (state, action) => {
      state.calculatorScreens = action.payload;
    },
    setCalculatorType: (state, action) => {
      state.calculatorType = action.payload;
    },
    setRemovalStorageCosts: (state, action) => {
      state.removalStorageCosts = action.payload;
    },
    setBuyPrice: (state, action) => {
      state.buyPrice = action.payload;
    },
    setSellPrice: (state, action) => {
      state.sellPrice = action.payload;
    },
    setAgencyFee: (state, action) => {
      state.agencyFee = action.payload;
    },
    setCurrentScreen: (state, action) => {
      state.currentScreen = action.payload;
    },
    setOtherMovingCosts: (state, action) => {
      state.otherMovingCosts = action.payload;
    },
    setStampDutyCosts: (state, action) => {
      state.stampDutyCosts = action.payload;
    },
    setBuyConveyancingCosts: (state, action) => {
      state.buyConveyancingCosts = action.payload;
    },
    setSellConveyancingCosts: (state, action) => {
      state.sellConveyancingCosts = action.payload;
    },
    setOtherLegalCosts: (state, action) => {
      state.otherLegalCosts = action.payload;
    },
    setValuationTypeValue: (state, action) => {
      state.valuationTypeValue = action.payload;
    },
    setIsBuyToLet: (state, action) => {
      state.isBuyToLet = action.payload;
    },
    resetCalculator: (state) => {
      state = undefined;
      console.log("reset", state);
    },
    reset: (state) => {},
  },
});

export const {
  incrementScreenIndex,
  decrementScreenIndex,
  setCalculatorType,
  setCurrentScreen,
  resetScreenIndex,
  resetCalculator,
  setCalculatorScreens,
  reset,
  setStampDutyCosts,
  setIsBuyToLet,
  setBuyPrice,
  setSellPrice,
  setAgencyFee,
  setBuyConveyancingCosts,
  setSellConveyancingCosts,
  setOtherLegalCosts,
  setValuationTypeValue,
  setRemovalStorageCosts,
  setOtherMovingCosts,
} = movingCostCalculatorSlice.actions;

export default movingCostCalculatorSlice.reducer;
