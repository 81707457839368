import { configureStore, combineReducers } from "@reduxjs/toolkit"
import movingCostCalculatorReducer from "./features/movingCostCalculatorSlice"

const combinedReducer = combineReducers({
  movingCostCalculator: movingCostCalculatorReducer,
})

const rootReducer = (state, action) => {
  if (action.type === "movingCostCalculator/reset") {
    state = undefined
  }
  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
})
