import React from "react"
import { ApolloProvider } from "@apollo/client"
import { client } from "./client"
// Redux imports
import { Provider } from "react-redux"
import { store } from "../redux/store"

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </Provider>
)
